* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 100vh;
  width: 100%;
}

.app:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.75;
  background-image: url("../../../public/img/B1-Pattern-Grey-Light.svg");
}

.app::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    var(--Primary-Grey-025) 75%,
    var(--Primary-Grey-025) 100%
  );
}

a {
  text-decoration: none; /* no underline */
}

input {
  all: unset;
}

.ant-notification {
  z-index: 2000 !important;
}

.ant-popover {
  z-index: 2000 !important;
}

.ant-input:hover {
  border-color: #37ab49 !important;
  border-top-color: #37ab49 !important;
}

.ant-input:focus {
  border-color: #37ab49 !important;
  border-top-color: #37ab49 !important;
}

.ant-tour-inner {
  background-color: #37ab49 !important;
}

.ant-tour-arrow {
  --antd-arrow-background-color: #37ab49 !important;
}

.ant-tour-prev-btn {
  background-color: #37ab49 !important;
}

.ant-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ant-menu-item:hover {
  border-radius: 0 !important;
}

.ant-menu-title-content {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.ant-menu-item-selected {
  width: calc(100% - 4px) !important;
  border-radius: 0 !important;
  background-color: var(--primary-005, var(--Primary-005)) !important;
  box-shadow: -3px 0px 0px 0px var(--Primary-400) inset;
  color: var(--Primary-400) !important;
}

.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--Primary-400) !important;
  background-color: white !important;
}

.ant-layout-sider-trigger {
  height: 40px !important;
}

.ant-btn-primary {
  background-color: var(--Primary-400) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black !important;
}

.ant-input-disabled {
  color: black !important;
}

.ManageTable {
  margin-bottom: 15px;
}

.MuiButton-textPrimary {
  color: var(--Primary-400) !important;
}

.dsg-container {
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 4px;
  margin-top: "10px";
}

::-webkit-scrollbar-track {
  background: none;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background: rgba(153, 153, 153, 0.4);
  border-radius: 1px;
}

@supports not selector(::-webkit-scrollbar) {
  html {
    scrollbar-color: rgba(153, 153, 153, 0.4) none;
  }
}
