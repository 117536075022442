:root {
  --Primary-005: #f5fbf6;
  --Primary-010: #ebf7ed;
  --Primary-025: #cdead1;
  --Primary-050: #9bd5a4;
  --Primary-100: #73c480;
  --Primary-200: #5fbc6d;
  --Primary-300: #4bb35b;
  --Primary-400: #37ab49;
  --Primary-500: #329a42;
  --Primary-600: #2c893a;
  --Primary-700: #21672c;
  --Primary-800: #16441d;
  --Primary-900: #0b220f;

  --Primary-Grey-005: #fafafa;
  --Primary-Grey-010: #f2f2f2;
  --Primary-Grey-025: #ededed;
  --Primary-Grey-050: #dadada;
  --Primary-Grey-100: #999999;
  --Primary-Grey-200: #777777;
  --Primary-Grey-300: #555555;
  --Primary-Grey-400: #444042;
  --Primary-Grey-500: #3d3a3b;
  --Primary-Grey-600: #363335;
  --Primary-Grey-700: #292628;
  --Primary-Grey-800: #1b1a1a;
  --Primary-Grey-900: #0e0d0d;

  --White: #ffffff;
  --Black: #000000;

  --Primary-Orange-005: #fefaf2;
  --Primary-Orange-010: #fef4e5;
  --Primary-Orange-025: #fce4bf;
  --Primary-Orange-050: #f9c880;
  --Primary-Orange-100: #f7b34c;
  --Primary-Orange-200: #f5a833;
  --Primary-Orange-300: #f49d19;
  --Primary-Orange-400: #f39200;
  --Primary-Orange-500: #e48600;
  --Primary-Orange-600: #d67a00;
  --Primary-Orange-700: #b96100;
  --Primary-Orange-800: #9c4900;
  --Primary-Orange-900: #612500;

  --Primary-Red-005: #fef5f4;
  --Primary-Red-010: #fcebea;
  --Primary-Red-025: #f9ccca;
  --Primary-Red-050: #f29995;
  --Primary-Red-100: #ed706a;
  --Primary-Red-200: #eb5c55;
  --Primary-Red-300: #e8473f;
  --Primary-Red-400: #e6332a;
  --Primary-Red-500: #d22e26;
  --Primary-Red-600: #be2922;
  --Primary-Red-700: #951f19;
  --Primary-Red-800: #6d1411;
  --Primary-Red-900: #440a08;

  --Primary-Purple-005: #f7f4f9;
  --Primary-Purple-010: #f0e9f3;
  --Primary-Purple-025: #d9c8e0;
  --Primary-Purple-050: #b391c1;
  --Primary-Purple-100: #9466a8;
  --Primary-Purple-200: #85509c;
  --Primary-Purple-300: #753a8f;
  --Primary-Purple-400: #662483;
  --Primary-Purple-500: #5c2076;
  --Primary-Purple-600: #521d69;
  --Primary-Purple-700: #3d164f;
  --Primary-Purple-800: #290e34;
  --Primary-Purple-900: #14071a;

  --Primary-DarkBlue-005: #f2f2fb;
  --Primary-DarkBlue-010: #e5e6f7;
  --Primary-DarkBlue-025: #bfc0eb;
  --Primary-DarkBlue-050: #8889c8;
  --Primary-DarkBlue-100: #6364ac;
  --Primary-DarkBlue-200: #4e4fa0;
  --Primary-DarkBlue-300: #3d3e98;
  --Primary-DarkBlue-400: #2d2e87;
  --Primary-DarkBlue-500: #282979;
  --Primary-DarkBlue-600: #24256c;
  --Primary-DarkBlue-700: #1b1c51;
  --Primary-DarkBlue-800: #121236;
  --Primary-DarkBlue-900: #09091b;

  --Primary-CeruleanBlue-005: #f4f8fb;
  --Primary-CeruleanBlue-010: #e8f1f8;
  --Primary-CeruleanBlue-025: #c7dbed;
  --Primary-CeruleanBlue-050: #8eb8dc;
  --Primary-CeruleanBlue-100: #619ccd;
  --Primary-CeruleanBlue-200: #4a8dc6;
  --Primary-CeruleanBlue-300: #347fbf;
  --Primary-CeruleanBlue-400: #1d71b8;
  --Primary-CeruleanBlue-500: #1a66a6;
  --Primary-CeruleanBlue-600: #175a93;
  --Primary-CeruleanBlue-700: #11446e;
  --Primary-CeruleanBlue-800: #0c2d4a;
  --Primary-CeruleanBlue-900: #061725;

  --Primary-TurquoiseGreen-005: #f2fafa;
  --Primary-TurquoiseGreen-010: #e5f5f4;
  --Primary-TurquoiseGreen-025: #bfe7e4;
  --Primary-TurquoiseGreen-050: #80cfc8;
  --Primary-TurquoiseGreen-100: #4cbcb3;
  --Primary-TurquoiseGreen-200: #33b3a8;
  --Primary-TurquoiseGreen-300: #19a99d;
  --Primary-TurquoiseGreen-400: #00a092;
  --Primary-TurquoiseGreen-500: #009083;
  --Primary-TurquoiseGreen-600: #008075;
  --Primary-TurquoiseGreen-700: #006058;
  --Primary-TurquoiseGreen-800: #00403a;
  --Primary-TurquoiseGreen-900: #00201d;

  --Secondary-Maroon-005: #f7f4f9;
  --Secondary-Maroon-400: #662483;
  --Secondary-Maroon-600: #521d69;

  --Secondary-Teal-005: #f2fafa;
  --Secondary-Teal-400: #00a092;
  --Secondary-Teal-600: #008075;

  --Secondary-Blue-005: #f4f8fb;
  --Secondary-Blue-400: #1d71b8;
  --Secondary-Blue-600: #175a93;

  --Secondary-Red-005: #fef5f4;
  --Secondary-Red-400: #e6332a;
  --Secondary-Red-600: #be2922;

  --Secondary-Green-005: #fafcf4;
  --Secondary-Green-400: #95c11f;
  --Secondary-Green-600: #779a19;

  --Secondary-Purple-005: #f2f2fb;
  --Secondary-Purple-400: #2d2e87;
  --Secondary-Purple-600: #24256c;

  --Secondary-Orange-005: #fefaf2;
  --Secondary-Orange-400: #f39200;
  --Secondary-Orange-600: #d67a00;
}
