.xButton__primary{
  font-weight: 500;
}

.xButton__dashed:not(:disabled):not(.ant-btn-disabled):hover{
  background-color: var(--Primary-Grey-005);
  color: var(--Primary-400);
  border-color: var(--Primary-400);
}

.xButton__default:not(:disabled):not(.ant-btn-disabled):hover{
  background-color: var(--Primary-Grey-005);
  color: var(--Primary-400);
  border-color: var(--Primary-400);
}