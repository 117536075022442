th{
    background-color: var(--Primary-Grey-005) !important;
}

.MuiToolbar-root{
    background-color: var(--Primary-Grey-010) !important;
}

.MuiSwitch-switchBase{
    color: var(--Primary-400) !important;
}

.MuiSwitch-track{
    background-color: var(--Primary-100) !important;
}